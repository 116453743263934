import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkContrastAtStart } from "extra/theme";
import { smoothScroll } from "extra/functions";
import PAGE_TITLES from "constants/page_titles";

import CookieConfirm from "components/main/CookieConfirm/CookieConfirm";
import Header from "components/main/Header/Header";
import Sidebar from "components/main/Sidebar";
import PageHeader from "components/main/PageHeader";
import Routes from "routes/Routes";
import PageFooter from "components/main/PageFooter";
import Footer from "components/main/Footer"
import BackToTop from "components/buttons/BackToTop";

import "styles/main.scss";

export default function App() { 

	const location = useLocation();

	useEffect(() => { checkContrastAtStart()}, [])

	useEffect(() => {
	
		const site_title = "WYRZUCAMTO.PL";
		const page_title = PAGE_TITLES[ location.pathname ];
		document.head.querySelector( "title" ).innerHTML = page_title ? page_title + " - " + site_title : site_title;

		smoothScroll( 0 )
	}, [ location ])

	return (
		<>
			<Header/>
			
			<main>
				<Sidebar/>

				<div className="page">

					<PageHeader/>
					<Routes/>
					<PageFooter/>

				</div>
			</main>
			
			<Footer/>
			<BackToTop/>
			<CookieConfirm/>
		</>
	)
}