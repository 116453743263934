import { Link } from "react-router-dom";
import { AngleCircleIcon } from "svg/icons";
import "./link-with-circle.scss";

export default function LinkWithCircle({ to, label, extra_classes }) {
		
	return (
		<Link to={ to } className={`link-with-circle ${ extra_classes || "" }`}>
			<AngleCircleIcon/>
			<span> { label } </span>
		</Link>
	)
}

