import { useState, useEffect } from "react";

export default function useWindowScroll( fn ) {
	
	const [ state, setState ] = useState( fn() || null );

	useEffect(() => {

		const getState = () => setState( fn());

		window.addEventListener( "scroll", getState );
		return () => window.removeEventListener( "scroll", getState );
	}, []);

	return state;
}

