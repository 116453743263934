import { useRef, useState } from 'react';
import { toggleContrast } from "extra/theme";
import useWindowClick from "hooks/useWindowClick";

import FontSizeSwitcher from "components/general/FontSizeSwitcher";
import { ContrastIcon, AccessibilityIcon } from "svg/icons";
import { SignLanguage } from "svg/web";

const actions = [
	{
		key: "sign",
		target: "_blank",
		href: "https://tlumacz.um.gdynia.pl/",
		svg: <SignLanguage/>,
		title: "Tłumacz języka migowego"
	},
	{ 
		key: "contrast",
		href: "#",
		svg: <ContrastIcon/>, 
		title: "Wysoki kontrast", 
		onClick: e => {
			e.preventDefault(); 
			toggleContrast();
		}
	}
]

export default function HeaderAccessibility() {
	
	const main_box_ref = useRef();
	const [ show, setShow ] = useState( false );

	useWindowClick( e => {
		!main_box_ref.current.contains( e.target ) && setShow( false );
	});

	return (
		<div ref={ main_box_ref } className="header-accessibility">
			
			<div 
				className="header-accessibility-btn"
				onClick={() => setShow( !show )}	
			> 
				<AccessibilityIcon/>
				<span> Dostępność </span>
			</div>

			{ show && 
				<div className="header-accessibility-box">
					{ actions.map( item => (
						<div key={ item.key }>
							<a {...item }>
								{ item.svg }
							</a>

							<span> { item.title } </span>
						</div> 
					)) }

					<FontSizeSwitcher />
				</div>
			}
		</div>
	)
}

