import { useEffect } from "react";

export default function useWindowClick( fn ) {
	
	useEffect(() => {

		if ( !fn ) return;

		window.addEventListener( "click", fn );
		return () => window.removeEventListener( "click", fn );
	}, []);

	return null;
}
