import PageFooterBoxes from "./inner/PageFooterBoxes";
import PageFooterLinks from "./inner/PageFooterLinks";

import "./page-footer.scss"

export default function PageFooter() {
		
	return (
		<div className="page-footer">
			
			<PageFooterBoxes/>

			<div className="page-footer-bottom">
				
				<div className="page-footer-copyright"> 
					© { new Date().getFullYear()} Gdynia Moje Miasto
				</div>

				<PageFooterLinks/>
			</div>
		</div>
	)
}

