import { OurCityLogo, GdyniaAppLogo } from "svg/web";
import { BipIcon } from "svg/icons";

const logos = [
	{
		href: "https://bip.um.gdynia.pl/",
		svg: <BipIcon/>,
		alt: "BIP"
	},
	{
		href: 'https://nasze.miasto.gdynia.pl/cu/',
		svg: <OurCityLogo />,
		alt: 'Cyfrowy Urząd'
	},
	{
		href: 'https://www.gdynia.pl/aplikacja/',
		svg: <GdyniaAppLogo />,
		alt: 'Gdynia aplikacja'
	}
];

export default function HeaderLogos() {
		
	return (
		<div className="header-logos">
			{ logos.map( logo => (
				<a key={ logo.alt } title={ logo.alt } href={ logo.href } target="_blank" rel="noreferrer" >
					{ logo.svg }
					<span className="d-none"> { logo.alt } </span>
				</a>
			))}
		</div>
	)
}

