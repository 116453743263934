import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PATHS from "constants/paths";

import "./navigation.scss";

const links = [
	{ label: "Strona główna", path: "/" },
	{ label: "Standard to", path: PATHS.STANDARD },
	{ 
		label: "Segreguję to lepiej", 
		path: PATHS.SEGREGATE,
		children: [
			{ label: "odzież i tekstylia", path: PATHS.SEGREGATE_CLOTH },
			{ label: "szkło", path: PATHS.SEGREGATE_GLASS },
			{ label: "metale i tworzywa sztuczne", path: PATHS.SEGREGATE_PLASTIC },
			{ label: "papier", path: PATHS.SEGREGATE_PAPER },
			{ label: "kuchenne (bio)", path: PATHS.SEGREGATE_BIO },
			{ label: "pozostałości po segregacji", path: PATHS.SEGREGATE_RESIDUES },
			{ label: "ODPADY ZIELONE (bio)", path: PATHS.SEGREGATE_GREEN },
			{ label: "popiół", path: PATHS.SEGREGATE_ASH },
			{ label: "Zużyty sprzęt elektryczny i elektroniczny", path: PATHS.SEGREGATE_ELECTRICAL },
			{ label: "odpady wielkogabarytowe", path: PATHS.SEGREGATE_BULKY },
			{ label: "odpady niebezpieczne", path: PATHS.SEGREGATE_HAZARDOUS },
			{ label: "odpady budowlane", path: PATHS.SEGREGATE_CONSTRUCTION },
		]
	},
	{ label: "Kiedy to?", path: PATHS.WHEN_IS_IT },
	{ label: "Ile to?", path: PATHS.HOM_MUCH },
	{ label: "Pobieram to", path: PATHS.LOOKING_FOR_INFO },
	{ label: "Zarządzam nieruchomością", path: PATHS.PROPERTY_MANAGER },
	{ label: "Zamawiam odbiór", path: PATHS.ORDER_ONLINE },
	{ label: "Deklaracja", path: PATHS.DECLARATION },
	{ label: "Prawo", path: PATHS.LAW },
	{ label: "Kontakt", path: PATHS.CONTACT }
]

export default function Navigation( props ) {
	
	const [ visible, setVisible ] = useState( false );
	const pathname = useLocation().pathname;

	useEffect(() => { setVisible( false )}, [ pathname ])

	return (
		<> 
			<a 
				href="#" 
				className={`navigation-button ${ visible ? "opened" : "" }`}
				onClick={ e => {
					e.preventDefault();
					setVisible( v => !v );
				}}
			>
				<span/>
				<span/>
				<span/>
			</a>

			<nav className={`navigation has-scrollbar ${ visible ? "visible" : "" }`}>
				<ul> 
					{ links.map(({ label, path, children }) => {
						
						const li_class = children && ( pathname === path || children.find( i => i.path === pathname )) ? "open" : "";

						return (
							<li 
								key={ path }
								className={ li_class }
							>
								
								<NavLink 
									to={ path } 
									className={({ isActive }) => isActive ? "active" : "" }
								> 
									{ label } 
								</NavLink>

								{ children && !!children.length && 
									<ul> 
										{ children.map(({ label, path }) => (
											<li key={ path }> 
												<NavLink 
													to={ path } 
													className={({ isActive }) => isActive ? "active" : "" }
												> 
													{ label } 
												</NavLink>
											</li>
										)) }
									</ul>
								}
							</li>
						)
					}) }
				</ul>
			</nav>
		</>
	)
}