import { Suspense, lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom"
import PATHS from "constants/paths";
import Loader from "components/general/Loader";

const MainPage = lazy(() => import( "pages/MainPage" ));
const StandardPage = lazy(() => import( "pages/StandardPage" ));
const SegregatePage = lazy(() => import( "pages/SegregatePage" ));
const SegregateClothPage = lazy(() => import( "pages/SegregateClothPage" ));
const SegregateGlassPage = lazy(() => import( "pages/SegregateGlassPage" ));
const SegregatePlasticPage = lazy(() => import( "pages/SegregatePlasticPage" ));
const SegregatePaperPage = lazy(() => import( "pages/SegregatePaperPage" ));
const SegregateBioPage = lazy(() => import( "pages/SegregateBioPage" ));
const SegregateResiduesPage = lazy(() => import( "pages/SegregateResiduesPage" ));
const SegregateGreenPage = lazy(() => import( "pages/SegregateGreenPage" ));
const SegregateAshPage = lazy(() => import( "pages/SegregateAshPage" ));
const SegregateElectricalPage = lazy(() => import( "pages/SegregateElectricalPage" ));
const SegregateBulkyPage = lazy(() => import( "pages/SegregateBulkyPage" ));
const SegregateHazardousPage = lazy(() => import( "pages/SegregateHazardousPage" ));
const SegregateConstructionPage = lazy(() => import( "pages/SegregateConstructionPage" ));
const WhenIsItPage = lazy(() => import( "pages/WhenIsItPage" ));
const HowMuchPage = lazy(() => import( "pages/HowMuchPage" ));
const LookingForInfoPage = lazy(() => import( "pages/LookingForInfoPage" ));
const PropertyManagerPage = lazy(() => import( "pages/PropertyManagerPage" ));
const OrderOnlinePage = lazy(() => import( "pages/OrderOnlinePage" ));
const DeclarationPage = lazy(() => import( "pages/DeclarationPage" ));
const MakeDeclarationPage = lazy(() => import( "pages/MakeDeclarationPage" ));
const LawPage = lazy(() => import( "pages/LawPage" ));
const ContactPage = lazy(() => import( "pages/ContactPage" ));
const FAQPage = lazy(() => import( "pages/FAQPage" ));
const PrivacyPolicyPage = lazy(() => import( "pages/PrivacyPolicyPage" ));
const MunicipalWastePage = lazy(() => import( "pages/MunicipalWastePage" ));
const SearchPage = lazy(() => import( "pages/SearchPage" ));
const AvailabilityDeclarationPage = lazy(() => import( "pages/AvailabilityDeclarationPage" ));

export default function Routes() {
	return (
		<Suspense fallback={ <Loader style={{ height: "calc(100vh - 159px)" }}/>}> 
			<Switch>
				<Route path="/" element={ <MainPage/> }/>
				<Route path={ PATHS.STANDARD } element={ <StandardPage/> }/>
				
				<Route path={ PATHS.SEGREGATE } element={ <SegregatePage/> }/>
				<Route path={ PATHS.SEGREGATE_CLOTH } element={ <SegregateClothPage/> }/>
				<Route path={ PATHS.SEGREGATE_GLASS } element={ <SegregateGlassPage/> }/>
				<Route path={ PATHS.SEGREGATE_PLASTIC } element={ <SegregatePlasticPage/> }/> 
				<Route path={ PATHS.SEGREGATE_PAPER } element={ <SegregatePaperPage/> }/>  
				<Route path={ PATHS.SEGREGATE_BIO } element={ <SegregateBioPage/> }/> 
				<Route path={ PATHS.SEGREGATE_RESIDUES } element={ <SegregateResiduesPage/> }/>
				<Route path={ PATHS.SEGREGATE_GREEN } element={ <SegregateGreenPage/> }/>
				<Route path={ PATHS.SEGREGATE_ASH } element={ <SegregateAshPage/> }/>
				<Route path={ PATHS.SEGREGATE_ELECTRICAL } element={ <SegregateElectricalPage/> }/>
				<Route path={ PATHS.SEGREGATE_BULKY } element={ <SegregateBulkyPage/> }/>
				<Route path={ PATHS.SEGREGATE_HAZARDOUS } element={ <SegregateHazardousPage/> }/>
				<Route path={ PATHS.SEGREGATE_CONSTRUCTION } element={ <SegregateConstructionPage/> }/>

				<Route path={ PATHS.WHEN_IS_IT } element={ <WhenIsItPage/> }/>
				<Route path={ PATHS.HOM_MUCH } element={ <HowMuchPage/> }/>
				<Route path={ PATHS.LOOKING_FOR_INFO } element={ <LookingForInfoPage/> }/>
				<Route path={ PATHS.PROPERTY_MANAGER } element={ <PropertyManagerPage/> }/>
				<Route path={ PATHS.ORDER_ONLINE } element={ <OrderOnlinePage/> }/>
				<Route path={ PATHS.DECLARATION } element={ <DeclarationPage/> }/>
				<Route path={ PATHS.MAKE_DECLARATION } element={ <MakeDeclarationPage/> }/>
				<Route path={ PATHS.LAW } element={ <LawPage/> }/>
				<Route path={ PATHS.CONTACT } element={ <ContactPage/> }/>
				<Route path={ PATHS.FAQ } element={ <FAQPage/> }/>
				<Route path={ PATHS.PRIVACY_POLICY } element={ <PrivacyPolicyPage/> }/>
				<Route path={ PATHS.MUNICIPAL_WASTE } element={ <MunicipalWastePage/> }/>
				<Route path={ PATHS.SEARCH } element={ <SearchPage/> }/>
				<Route path={ PATHS.AVAILABILITY_DECLARATION } element={ <AvailabilityDeclarationPage/> }/>
			</Switch>
		</Suspense>
	)
} 