import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PATHS from "constants/paths";

import { SearchIcon } from "svg/icons";
import "./search-form.scss";

export default function SearchForm() {

	const navigate = useNavigate();
	const [ search, setSearch ] = useState( "" );
	
	const formSubmit = e => {
		e.preventDefault();

		const search_str = search;
		setSearch( "" );

		navigate( PATHS.SEARCH, {
			state: { search: search_str }
		})
	}

	return (
		<form 
			className="search-form" 
			action="get" 
			onSubmit={ formSubmit }
		>
			<label>
				<span> szukaj </span>

				<SearchIcon/>

				<input
					type="text" 
					name="search"
					placeholder="SZUKAJ"
					value={ search }
					onChange={ e => setSearch( e.target.value )}	
				/>
			</label>
		</form>
	)
}

