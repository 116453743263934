import { Link } from "react-router-dom";

import Navigation from "components/navigation/Navigation";
import { ReactComponent as LogoSvg } from "svg/logo.svg";
import "./sidebar.scss";

export default function Sidebar( props ) {
		
	return (
		<div className="sidebar">
			
			<Link to="/" className="sidebar-logo">
				<LogoSvg/>
			</Link>

			<Navigation/>
		</div>
	)
}

