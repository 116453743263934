const contrast_key = "contrast_version";

const isContrastTurnOn = () => localStorage.getItem( contrast_key ) === "on";

export const checkContrastAtStart = () => isContrastTurnOn() && turnOnContrast();

export const turnOnContrast = () => {
	localStorage.setItem( contrast_key, "on");
	document.body.classList.add("contrast");
}


export const turnOffContrast = () => {
	localStorage.setItem( contrast_key, "off");
	document.body.classList.remove("contrast");
}


export const toggleContrast = () => {
	!isContrastTurnOn() 
		? turnOnContrast()
		: turnOffContrast();
}


export const changeFontSize = action => {

	const body = document.body;
	let fontSize = parseInt( window.getComputedStyle( body ).fontSize.replace( "px", "" ));
	
	if ( action === 'less' && fontSize > 14 ) fontSize -= 1;
	if ( action === 'more' && fontSize < 22 ) fontSize += 1;
	if ( action === "normal") fontSize = 18;

	fontSize += "px";
	body.style.fontSize = fontSize;
};