import { ReactComponent as GCK } from "svg/GCK.svg";
import { ReactComponent as GCKText } from "svg/GCK-text.svg";

export default function GCKLogo() {
	return (
		<div>
			<GCK />
			<GCKText />
		</div>
	)
}