import { Link } from "react-router-dom";
import PATHS from "constants/paths";

import SearchForm from "components/form/SearchForm";
import { OrderIcon, FAQIcon } from "svg/icons";

import "./page-header.scss";

export default function PageHeader( props ) {
		
	return (
		<div className="page-header">
			
			<SearchForm/>

			<ul>
				<li>
					<OrderIcon/>
					<Link to={ PATHS.ORDER_ONLINE }> Zamawiam odbiór </Link>
				</li>

				<li>
					<FAQIcon/>
					<Link to={ PATHS.FAQ }> FAQ </Link>
				</li>
			</ul>

		</div>
	)
}