import { useState } from "react";
import { Link } from "react-router-dom";
import PATHS from "constants/paths";

import Button from "components/buttons/Button";
import "./cookie-confirm.scss";

export default function CookieConfirm() {
	
	const [ visible, setVisible ] = useState( !localStorage.getItem( "cookie" ));

	return visible ? (
		<div className="cookie-confirm">
			
			<p>
				Uprzejmie informujemy, że od dnia 25 maja 2018 r. obowiązuje Rozporządzenie Parlamentu Europejskiego Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/45/WE. Nowe prawo wprowadza zmiany w zasadach regulujących przetwarzanie danych osobowych. 
			</p>

			<p> 
				Szczegółowe zapisy znajdziesz w <Link to={ PATHS.PRIVACY_POLICY }> nowej polityce prywatności </Link> 
				portalu wyrzucam.to. Jednocześnie informujemy, że Państwa dane przetwarzane są w sposób bezpieczny, z należytą starannością i zgodnie z obowiązującymi 	przepisami. 
			</p>

			<Button onClick={() => {
				localStorage.setItem( "cookie", true );
				setVisible( false );
			}}> Rozumiem </Button>
		</div>
	) : null;
}