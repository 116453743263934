import PATHS from "constants/paths";
import { Link } from "react-router-dom";
import "./page-footer-links.scss";

export default function PageFooterLinks() {
		
	return (
		<nav className="page-footer-links">
			<Link to={ PATHS.AVAILABILITY_DECLARATION }> DEKLARACJA DOSTĘPNOŚCI </Link>
			<Link to={ PATHS.HOM_MUCH }> Opłaty </Link>
			<Link to={ PATHS.WHEN_IS_IT }> Harmonogram </Link>
			<Link to={ PATHS.CONTACT}> Kontakt </Link>
		</nav>
	)
}

