
const getDetectVariableTypeFunc = type => {
	return v => toString.call( v ) === `[object ${ type }]`
};

export const isFunction = getDetectVariableTypeFunc( "Function" );

export const isObject = getDetectVariableTypeFunc( "Object" );

export const isString = getDetectVariableTypeFunc( "String" );

export const smoothScroll = to => { 
	"scrollBehavior" in document.documentElement.style
		? window.scrollTo({ top: to, behavior: 'smooth' })
		: window.scrollTop = to;
}

export const getDownloadFullLink = file_link => {
	return ( window.config.ROUTER_BASENAME || "" ) + "/download" + file_link;
}

export const loadScript = options => {
	
	if ( !options.src ) return;
	if ( document.querySelector("#" + options.id )) return;

	const script = document.createElement('script');
	script.id  = options.id || "";
	script.src = options.src;
	script.type = options.type || "text/javascript";
	script.async = options.async;

	const place = options.place || document.body; 
	place.appendChild( script );
}

export const arrSum = arr => arr.reduce(( a, b ) => a + b, 0 );

export const getDebounceFn = fn => {

	let timer;

	return function(...args ) {
		clearTimeout( timer );
		timer = setTimeout(() => fn(...args ), 300 )
	}
}

export const getDependenciesForHook = p => {

	if ( !Array.isArray( p )) return []

	return p.reduce(( all_params, current ) => {

		const parse = c => {
			isObject( c ) 
				? Object.values( c ).forEach( p => parse( p ))
				: all_params.push( c )
		}
		
		parse( current );
		return all_params;

	}, [])
}