import PATHS from "./paths";

export default {
	"/": "",
	[ PATHS.STANDARD ] : "Standard to",
	[ PATHS.SEGREGATE ] : "Segreguję to",
	[ PATHS.SEGREGATE_GLASS ] : "Szkło",
	[ PATHS.SEGREGATE_PLASTIC ] : "Metale i tworzywa sztuczne",
	[ PATHS.SEGREGATE_PAPER ]: "Papier",
	[ PATHS.SEGREGATE_BIO ] : "Bio",
	[ PATHS.SEGREGATE_RESIDUES ] : "Pozostałości po segregacji",
	[ PATHS.SEGREGATE_GREEN ] : "Odpady zielone",
	[ PATHS.SEGREGATE_ASH ] : "Popiół",
	[ PATHS.SEGREGATE_ELECTRICAL ] : "Zużyty sprzęt elektryczny i elektroniczny",
	[ PATHS.SEGREGATE_BULKY ] : "Odpady wielkogabarytowe",
	[ PATHS.SEGREGATE_HAZARDOUS ] : "Odpady niebezpieczne",
	[ PATHS.SEGREGATE_CONSTRUCTION ] : "Odpady budowlane",
	[ PATHS.WHEN_IS_IT ] : "Kiedy to?",
	[ PATHS.HOM_MUCH ] : "Tanio to?",
	[ PATHS.LOOKING_FOR_INFO ] : "Pobieram to",
	[ PATHS.PROPERTY_MANAGER ] : "Jestem zarządcą",
	[ PATHS.ORDER_ONLINE ] : "Zamawiam odbiór",
	[ PATHS.DECLARATION ] : "Złóż deklarację",
	[ PATHS.LAW ] : "Prawo",
	[ PATHS.CONTACT ] : "Kontakt",
	[ PATHS.SEARCH ] : "Szukaj",
	[ PATHS.FAQ ] : "FAQ",
	[ PATHS.PRIVACY_POLICY ] : "Polityka prywatności",
	[ PATHS.MUNICIPAL_WASTE ] : "PSZOK",
	[ PATHS.AVAILABILITY_DECLARATION ] : "Deklaracja dostępności"
}