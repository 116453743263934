export default {
	STANDARD: "/standard-to",
	SEGREGATE: "/segreguje-to",
		SEGREGATE_CLOTH: "/segreguje-odziez-i-tekstylia",
		SEGREGATE_GLASS: "/segreguje-szklo",
		SEGREGATE_PLASTIC: "/segreguje-plastik",
		SEGREGATE_PAPER: "/segreguje-papier",
		SEGREGATE_BIO: "/segreguje-bio",
		SEGREGATE_RESIDUES: "/segreguje-zmieszane",
		SEGREGATE_GREEN: "/segreguje-zielone",
		SEGREGATE_ASH: "/segreguje-popiol",
		SEGREGATE_ELECTRICAL: "/segreguje-zsee",
		SEGREGATE_BULKY: "/segreguje-wielkogabarytowe",
		SEGREGATE_HAZARDOUS: "/segreguje-niebezpieczne",
		SEGREGATE_CONSTRUCTION: "/segreguje-budowlane",
		
	WHEN_IS_IT: "/kiedy-to",
	HOM_MUCH: "/tanio-to",
	LOOKING_FOR_INFO: "/szukam-informacji",
	PROPERTY_MANAGER: "/jestem-zarzadca",
	ORDER_ONLINE: "/zamow-online",
	DECLARATION: "/deklaracja",
	MAKE_DECLARATION: "/deklaracja-formularz",
	LAW: "/prawo",
	CONTACT: "/kontakt",

	SEARCH: "/szukaj",
	FAQ: "/faq",
	PRIVACY_POLICY: "/politykaprywatnosci",
	MUNICIPAL_WASTE: "/pszok",
	AVAILABILITY_DECLARATION: "/dostepnosc"
}