import { RegIcon, PadlockIcon } from "svg/icons";

const links = [
	{
		class: "reg",
		label: "Rejestracja",
		svg: <RegIcon/>,
		href: "https://konto.um.gdynia.pl/registration"
	},
	{
		class: "login",
		label: "Zaloguj się",
		svg: <PadlockIcon/>,
		href: "https://konto.um.gdynia.pl/login"
	}
]

export default function HeaderAppButtons() {
	return (
		<div className="header-app-buttons">
			{ links.map( l => (
				<a
					key={ l.href }
					href={ l.href }
					className={ l.class }
					target="_blank"
				>
					
					<span> { l.label } </span>
					{ l.svg }
				</a>
			))}
		</div>
	)
}

