import { AngleTopIcon } from "svg/icons";
import { smoothScroll } from "extra/functions";
import useWindowScroll from "hooks/useWindowScroll";

import "./back-to-top.scss";

export default function BackToTop() {
	
	const visible = useWindowScroll( isButtonVisible );

	function isButtonVisible() {
		return window.scrollY > 1000
	}

	return visible ? (
		<button 
			type="button" 
			className="back-to-top"
			onClick={() => smoothScroll( 0 )}
		>

			<AngleTopIcon/>
			<span className="d-none"> Back to top </span>
		</button>
	) : null
}