import PATHS from "constants/paths";
import LinkWithCircle from "components/navigation/LinkWithCircle";
import "./page-footer-boxes.scss";

const boxes = [
	{
		heading: "Kontakt",
		content: <p>W sprawie opłat lub deklaracji zadzwoń: <strong>58 527 28 00</strong><br/><br/>W sprawie reklamacji, odbióru odpadów wielkogabarytowych, zasad segregacji zadzwoń: <br/><strong>58 527 32 90</strong><br/><br/>Lub wyślij wiadomość na e-mail: <a href="mailto:wyrzucamto@gdynia.pl"> wyrzucamto@gdynia.pl </a> </p>
	},
	{
		heading: "System powiadomień",
		content: <p>Chcesz bezpłatnie otrzymywać przypomnienia o zbliżającym się odbiorze odpadów segregowanych? Wypełnij krótki formularz i zapisz się do systemu powiadomień SMS / e-mail.</p>,
		links: [{ label: "Dołącz do systemu powiadomień", to: PATHS.WHEN_IS_IT }]
	},
	{
		heading: "Zobacz także",
		links: [
			{ label: "PSZOK", to: PATHS.MUNICIPAL_WASTE, extra_classes: "sunshade" },
			{ label: "Prawo", to: PATHS.LAW, extra_classes: "sunshade" },
			{ label: "FAQ", to: PATHS.FAQ, extra_classes: "sunshade" },
			{ label: "Polityka prywatności", to: PATHS.PRIVACY_POLICY, extra_classes: "sunshade" }
		]
	}
]

export default function PageFooterBoxes( props ) {
		
	return (
		<div className="page-footer-boxes">
			{ boxes && !!boxes.length &&
				boxes.map(({ heading, content, links }, index ) => (
					<div key={ index } className="page-footer-box"> 
						
						<h3> { heading } </h3>
						
						{ content }

						{ links && !!links.length &&
							<ul> 
							{ links.map(( link, index ) => (
								<li key={ index }>
									<LinkWithCircle {...link }/> 
								</li>
							)) }
							</ul>
						}
					</div>
				))
			}
		</div>
	)
}

