import React from 'react';
import GCKLogo from 'components/general/GCKLogo';

const FooterBottom = () => (
	<div className="footer-bottom">
		<span> powered by </span> 
		<GCKLogo />
	</div>
)

export default FooterBottom;